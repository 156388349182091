<template>
  <el-main>
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="订单号码：">
        <el-input size="small" placeholder="请输入订单号码" v-model="searchForm.ordersn"></el-input>
      </el-form-item>
      <el-form-item label="用户信息：">
        <el-input size="small" placeholder="请输入用户信息" v-model="searchForm.nick_name"></el-input>
      </el-form-item>
      <el-form-item label="用户手机号：">
        <el-input size="small" placeholder="请输入用户手机号" v-model="searchForm.mobile"></el-input>
      </el-form-item>
      <el-form-item label="课程标题：">
        <el-input size="small" placeholder="请输入课程标题" v-model="searchForm.title"></el-input>
      </el-form-item>
      <el-form-item label="支付时间：">
        <el-date-picker v-model="searchForm.starttime" type="daterange" value-format="timestamp" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="search">搜索</el-button>
        <el-button size="small" type="text" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="ordersn" label="订单号码" align="center"></el-table-column>
      <el-table-column prop="image" label="课程图片" align="center">
        <template slot-scope="scope">
          <el-image :src="scope.row.image" style="width: 80px; height: 50px"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="课程标题" align="center"></el-table-column>
      <el-table-column prop="class_time" label="课时" align="center"></el-table-column>
      <el-table-column prop="price" label="支付金额" align="center">
        <template slot-scope="scope">￥{{ scope.row.price }}</template>
      </el-table-column>
      <el-table-column prop="avatar" label="用户信息" align="center">
        <template v-slot="{ row }">
          <userInfo :name="row.nick_name" :avatar="row.avatar"></userInfo>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="用户手机号" align="center"></el-table-column>
      <el-table-column prop="avatar" label="支付时间" align="center">
        <template v-slot="{ row }">
          {{ row.pay_time | getDateformat }}
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import _ from 'lodash';
import userInfo from '@/components/userInfo';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    userInfo,
    Paging,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        mobile: '',
        title: '',
        starttime: '',
        endtime: '',
        ordersn: '',
        nick_name: '',
      },
      total: 0,
      list: [],
    };
  },
  filters: {
    getDateformat: getDateformat,
  },
  created() {
    this.getList();
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        mobile: '',
        title: '',
        starttime: '',
        endtime: '',
        ordersn: '',
        nick_name: '',
      };
      this.getList();
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if(searchForm.starttime){
          searchForm.endtime = searchForm.starttime[1] /1000;
          searchForm.starttime = searchForm.starttime[0] /1000;
      }
      this.$axios.post(this.$api.beauty.courseOrder, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
    .el-main{
        background: #fff;
    }
</style>